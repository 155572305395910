var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isReady
    ? _c(
        "div",
        { style: _vm.customStyle },
        [
          !_vm.isWindowOpen
            ? _c(
                "a",
                {
                  staticClass: "saichat-launcher",
                  attrs: { id: "sci-chatwindow" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.toggleWindow($event)
                    }
                  }
                },
                [
                  _vm.constObj.LAUNCHER_ICON.outsideLeft.type === "img"
                    ? _c("img", {
                        staticClass: "saichat-launcher__outsideLeft animated",
                        class: [
                          _vm.constObj.LAUNCHER_ICON.outsideLeft.effect.motion,
                          _vm.constObj.LAUNCHER_ICON.outsideLeft.effect
                            .duration,
                          {
                            infinite:
                              _vm.constObj.LAUNCHER_ICON.outsideLeft.effect
                                .infinite
                          }
                        ],
                        attrs: {
                          src: _vm.getResourceImg(
                            _vm.constObj.LAUNCHER_ICON.outsideLeft.src[0]
                          )
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.constObj.LAUNCHER_ICON.outsideLeft.type === "icon"
                    ? _c(
                        "span",
                        {
                          staticClass: "saichat-launcher__outsideLeft",
                          class: {
                            "saichat-launcher__smallSize":
                              _vm.constObj.LAUNCHER_ICON.outsideLeft.size ===
                              "small",
                            "saichat-launcher__mediumSize":
                              _vm.constObj.LAUNCHER_ICON.outsideLeft.size ===
                              "medium",
                            "saichat-launcher__largeSize":
                              _vm.constObj.LAUNCHER_ICON.outsideLeft.size ===
                              "large"
                          }
                        },
                        [
                          _c("font-awesome-icon", {
                            staticClass: "animated",
                            class: [
                              _vm.constObj.LAUNCHER_ICON.outsideLeft.effect
                                .motion,
                              _vm.constObj.LAUNCHER_ICON.outsideLeft.effect
                                .duration,
                              {
                                infinite:
                                  _vm.constObj.LAUNCHER_ICON.outsideLeft.effect
                                    .infinite,
                                "saichat-launcher__smallIcon":
                                  _vm.constObj.LAUNCHER_ICON.outsideLeft
                                    .size === "small",
                                "saichat-launcher__mediumIcon":
                                  _vm.constObj.LAUNCHER_ICON.outsideLeft
                                    .size === "medium",
                                "saichat-launcher__largeIcon":
                                  _vm.constObj.LAUNCHER_ICON.outsideLeft
                                    .size === "large"
                              }
                            ],
                            attrs: {
                              icon: _vm.constObj.LAUNCHER_ICON.outsideLeft.src
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.constObj.LAUNCHER_BUTTON.type === "img"
                    ? _c("img", {
                        staticClass: "saichat-launcher__button animated",
                        class: [
                          _vm.constObj.LAUNCHER_BUTTON.motion,
                          _vm.constObj.LAUNCHER_BUTTON.duration,
                          {
                            infinite:
                              _vm.constObj.LAUNCHER_ICON.outsideLeft.effect
                                .infinite
                          }
                        ],
                        attrs: {
                          src: _vm.getResourceImg(
                            _vm.constObj.LAUNCHER_BUTTON.src
                          )
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  (_vm.constObj.LAUNCHER_ICON.left.type === "icon" &&
                    _vm.constObj.LAUNCHER_ICON.left.size === "medium") ||
                  _vm.constObj.LAUNCHER_ICON.left.size === "large"
                    ? _c(
                        "span",
                        {
                          staticClass: "saichat-launcher__insideLeft",
                          class: {
                            "saichat-launcher__mediumSize":
                              _vm.constObj.LAUNCHER_ICON.left.size === "medium",
                            "saichat-launcher__largeSize":
                              _vm.constObj.LAUNCHER_ICON.left.size === "large"
                          }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "animated",
                              class: [
                                _vm.constObj.LAUNCHER_ICON.left.effect.motion,
                                _vm.constObj.LAUNCHER_ICON.left.effect.duration,
                                {
                                  infinite:
                                    _vm.constObj.LAUNCHER_BUTTON.infinite,
                                  "saichat-launcher__mediumIcon":
                                    _vm.constObj.LAUNCHER_ICON.left.size ===
                                    "medium",
                                  "saichat-launcher__largeIcon":
                                    _vm.constObj.LAUNCHER_ICON.left.size ===
                                    "large"
                                }
                              ]
                            },
                            [
                              _vm.constObj.LAUNCHER_ICON.left.src[0] ===
                              "default"
                                ? _c(
                                    "svg",
                                    {
                                      attrs: {
                                        xmlns: "http://www.w3.org/2000/svg",
                                        viewBox: "0 0 512 512",
                                        "svg-inline": "",
                                        role: "presentation",
                                        focusable: "false",
                                        tabindex: "-1"
                                      }
                                    },
                                    [
                                      _c("path", {
                                        staticClass: "st0",
                                        attrs: {
                                          d:
                                            "M433.398 67.678C385.562 27.772 322.558 5.795 256 5.795c-66.554 0-129.554 21.977-177.394 61.883C27.918 109.963 0 167.037 0 228.38c0 61.348 27.918 118.418 78.606 160.703 44.289 36.946 101.57 58.52 162.641 61.52l56.422 44.062a54.452 54.452 0 0033.531 11.539 54.451 54.451 0 0020.41-3.969 54.47 54.47 0 0033.523-42.93l5.848-41.672C465.442 377.494 512 306.044 512 228.38c0-61.343-27.914-118.417-78.602-160.702zm15.301 209.98a149.014 149.014 0 01-4.141 10.141c-.668 1.465-1.445 2.894-2.16 4.34-1.062 2.156-2.094 4.328-3.262 6.438-.973 1.766-2.066 3.476-3.114 5.206-1.078 1.786-2.109 3.594-3.262 5.344-1.023 1.558-2.16 3.062-3.242 4.59-1.347 1.902-2.656 3.828-4.09 5.68-.82 1.058-1.722 2.07-2.57 3.117-1.855 2.282-3.703 4.57-5.687 6.774-.359.398-.758.778-1.122 1.172-19.656 21.398-45.304 38.77-74.898 50.301l-6.625 47.211-3.406 23.71-70.785-55.277c-1.446.027-2.879.094-4.336.094-111.301 0-201.531-75.27-201.531-168.118 0-92.847 90.23-168.121 201.531-168.121 111.305 0 201.535 75.274 201.535 168.121 0 16.844-2.973 33.102-8.5 48.438-.1.285-.229.558-.335.839z"
                                        }
                                      }),
                                      _c("path", {
                                        staticClass: "st0",
                                        attrs: {
                                          d:
                                            "M154.359 207.787c-15.066 0-27.281 12.215-27.281 27.282 0 15.07 12.214 27.285 27.281 27.285 15.07 0 27.286-12.214 27.286-27.285 0-15.067-12.215-27.282-27.286-27.282zM256 207.787c-15.066 0-27.282 12.215-27.282 27.282 0 15.07 12.215 27.285 27.282 27.285 15.07 0 27.285-12.214 27.285-27.285 0-15.067-12.215-27.282-27.285-27.282zM357.645 207.787c-15.07 0-27.286 12.215-27.286 27.282 0 15.07 12.215 27.285 27.286 27.285 15.066 0 27.281-12.214 27.281-27.285 0-15.067-12.215-27.282-27.281-27.282z"
                                        }
                                      })
                                    ]
                                  )
                                : _c("font-awesome-icon", {
                                    attrs: {
                                      icon: _vm.constObj.LAUNCHER_ICON.left.src
                                    }
                                  })
                            ],
                            1
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.constObj.LAUNCHER_BUTTON.type === "default"
                    ? _c(
                        "span",
                        {
                          staticClass: "saichat-launcher__button animated",
                          class: [
                            _vm.constObj.LAUNCHER_BUTTON.motion,
                            _vm.constObj.LAUNCHER_BUTTON.duration,
                            {
                              infinite: _vm.constObj.LAUNCHER_BUTTON.infinite,
                              "saichat-launcher__leftIconButton":
                                _vm.constObj.LAUNCHER_ICON.left.size ===
                                  "medium" ||
                                _vm.constObj.LAUNCHER_ICON.left.size ===
                                  "large",
                              "saichat-launcher__rightIconButton":
                                _vm.constObj.LAUNCHER_ICON.right.size ===
                                  "medium" ||
                                _vm.constObj.LAUNCHER_ICON.right.size ===
                                  "large"
                            }
                          ],
                          staticStyle: { padding: "7px 16px", height: "auto" }
                        },
                        [
                          _vm.constObj.LAUNCHER_ICON.left.type === "img"
                            ? _c("img", {
                                staticClass:
                                  "saichat-launcher__insideLeft animated",
                                class: [
                                  _vm.constObj.LAUNCHER_ICON.left.effect.motion,
                                  _vm.constObj.LAUNCHER_ICON.left.effect
                                    .duration,
                                  {
                                    infinite:
                                      _vm.constObj.LAUNCHER_ICON.left.effect
                                        .infinite
                                  }
                                ],
                                attrs: {
                                  src: _vm.getResourceImg(
                                    _vm.constObj.LAUNCHER_ICON.left.src[0]
                                  )
                                }
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.constObj.LAUNCHER_ICON.left.type === "icon" &&
                          _vm.constObj.LAUNCHER_ICON.left.size === "small"
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "saichat-launcher__insideLeftSmall saichat-launcher__smallIcon animated",
                                  class: [
                                    _vm.constObj.LAUNCHER_ICON.left.effect
                                      .motion,
                                    _vm.constObj.LAUNCHER_ICON.left.effect
                                      .duration,
                                    {
                                      infinite:
                                        _vm.constObj.LAUNCHER_ICON.left.effect
                                          .infinite
                                    }
                                  ]
                                },
                                [
                                  _vm.constObj.LAUNCHER_ICON.left.src[0] ===
                                  "default"
                                    ? _c(
                                        "svg",
                                        {
                                          attrs: {
                                            xmlns: "http://www.w3.org/2000/svg",
                                            viewBox: "0 0 512 512",
                                            "svg-inline": "",
                                            role: "presentation",
                                            focusable: "false",
                                            tabindex: "-1"
                                          }
                                        },
                                        [
                                          _c("path", {
                                            staticClass: "st0",
                                            attrs: {
                                              d:
                                                "M433.398 67.678C385.562 27.772 322.558 5.795 256 5.795c-66.554 0-129.554 21.977-177.394 61.883C27.918 109.963 0 167.037 0 228.38c0 61.348 27.918 118.418 78.606 160.703 44.289 36.946 101.57 58.52 162.641 61.52l56.422 44.062a54.452 54.452 0 0033.531 11.539 54.451 54.451 0 0020.41-3.969 54.47 54.47 0 0033.523-42.93l5.848-41.672C465.442 377.494 512 306.044 512 228.38c0-61.343-27.914-118.417-78.602-160.702zm15.301 209.98a149.014 149.014 0 01-4.141 10.141c-.668 1.465-1.445 2.894-2.16 4.34-1.062 2.156-2.094 4.328-3.262 6.438-.973 1.766-2.066 3.476-3.114 5.206-1.078 1.786-2.109 3.594-3.262 5.344-1.023 1.558-2.16 3.062-3.242 4.59-1.347 1.902-2.656 3.828-4.09 5.68-.82 1.058-1.722 2.07-2.57 3.117-1.855 2.282-3.703 4.57-5.687 6.774-.359.398-.758.778-1.122 1.172-19.656 21.398-45.304 38.77-74.898 50.301l-6.625 47.211-3.406 23.71-70.785-55.277c-1.446.027-2.879.094-4.336.094-111.301 0-201.531-75.27-201.531-168.118 0-92.847 90.23-168.121 201.531-168.121 111.305 0 201.535 75.274 201.535 168.121 0 16.844-2.973 33.102-8.5 48.438-.1.285-.229.558-.335.839z"
                                            }
                                          }),
                                          _c("path", {
                                            staticClass: "st0",
                                            attrs: {
                                              d:
                                                "M154.359 207.787c-15.066 0-27.281 12.215-27.281 27.282 0 15.07 12.214 27.285 27.281 27.285 15.07 0 27.286-12.214 27.286-27.285 0-15.067-12.215-27.282-27.286-27.282zM256 207.787c-15.066 0-27.282 12.215-27.282 27.282 0 15.07 12.215 27.285 27.282 27.285 15.07 0 27.285-12.214 27.285-27.285 0-15.067-12.215-27.282-27.285-27.282zM357.645 207.787c-15.07 0-27.286 12.215-27.286 27.282 0 15.07 12.215 27.285 27.286 27.285 15.066 0 27.281-12.214 27.281-27.285 0-15.067-12.215-27.282-27.281-27.282z"
                                            }
                                          })
                                        ]
                                      )
                                    : _c("font-awesome-icon", {
                                        attrs: {
                                          icon:
                                            _vm.constObj.LAUNCHER_ICON.left.src
                                        }
                                      })
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "span",
                            { staticClass: "saichat-launcher__title" },
                            [_vm._v(_vm._s(_vm.launcherTitle))]
                          ),
                          _vm._v(" "),
                          _vm.constObj.LAUNCHER_ICON.right.type === "img"
                            ? _c("img", {
                                staticClass:
                                  "saichat-launcher__insideRight animated",
                                class: [
                                  _vm.constObj.LAUNCHER_ICON.right.effect
                                    .motion,
                                  _vm.constObj.LAUNCHER_ICON.right.effect
                                    .duration,
                                  {
                                    infinite:
                                      _vm.constObj.LAUNCHER_ICON.right.effect
                                        .infinite
                                  }
                                ],
                                attrs: {
                                  src: _vm.getResourceImg(
                                    _vm.constObj.LAUNCHER_ICON.right.src[0]
                                  )
                                }
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.constObj.LAUNCHER_ICON.right.type === "icon" &&
                          _vm.constObj.LAUNCHER_ICON.right.size === "small"
                            ? _c("font-awesome-icon", {
                                staticClass:
                                  "saichat-launcher__insideRightSmall saichat-launcher__smallIcon animated",
                                class: [
                                  _vm.constObj.LAUNCHER_ICON.right.effect
                                    .motion,
                                  _vm.constObj.LAUNCHER_ICON.right.effect
                                    .duration,
                                  {
                                    infinite:
                                      _vm.constObj.LAUNCHER_ICON.right.effect
                                        .infinite
                                  }
                                ],
                                attrs: {
                                  icon: _vm.constObj.LAUNCHER_ICON.right.src
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.constObj.LAUNCHER_ICON.outsideRight.type === "img"
                    ? _c("img", {
                        staticClass: "saichat-launcher__outsideRight animated",
                        class: [
                          _vm.constObj.LAUNCHER_ICON.outsideRight.effect.motion,
                          _vm.constObj.LAUNCHER_ICON.outsideRight.effect
                            .duration,
                          {
                            infinite:
                              _vm.constObj.LAUNCHER_ICON.outsideRight.effect
                                .infinite
                          }
                        ],
                        attrs: {
                          src: _vm.getResourceImg(
                            _vm.constObj.LAUNCHER_ICON.outsideRight.src[0]
                          )
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.constObj.LAUNCHER_ICON.outsideRight.type === "icon"
                    ? _c(
                        "span",
                        {
                          staticClass: "saichat-launcher__outsideRight",
                          class: {
                            "saichat-launcher__smallSize":
                              _vm.constObj.LAUNCHER_ICON.outsideRight.size ===
                              "small",
                            "saichat-launcher__mediumSize":
                              _vm.constObj.LAUNCHER_ICON.outsideRight.size ===
                              "medium",
                            "saichat-launcher__largeSize":
                              _vm.constObj.LAUNCHER_ICON.outsideRight.size ===
                              "large"
                          }
                        },
                        [
                          _c("font-awesome-icon", {
                            staticClass: "fa-lg animated",
                            class: [
                              _vm.constObj.LAUNCHER_ICON.outsideRight.effect
                                .motion,
                              _vm.constObj.LAUNCHER_ICON.outsideRight.effect
                                .duration,
                              {
                                infinite:
                                  _vm.constObj.LAUNCHER_ICON.outsideRight.effect
                                    .infinite,
                                "saichat-launcher__smallIcon":
                                  _vm.constObj.LAUNCHER_ICON.outsideRight
                                    .size === "small",
                                "saichat-launcher__mediumIcon":
                                  _vm.constObj.LAUNCHER_ICON.outsideRight
                                    .size === "medium",
                                "saichat-launcher__largeIcon":
                                  _vm.constObj.LAUNCHER_ICON.outsideRight
                                    .size === "large"
                              }
                            ],
                            attrs: {
                              icon: _vm.constObj.LAUNCHER_ICON.outsideRight.src
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  (_vm.constObj.LAUNCHER_ICON.right.type === "icon" &&
                    _vm.constObj.LAUNCHER_ICON.right.size === "medium") ||
                  _vm.constObj.LAUNCHER_ICON.right.size === "large"
                    ? _c(
                        "span",
                        {
                          staticClass: "saichat-launcher__insideRight",
                          class: {
                            "saichat-launcher__mediumSize":
                              _vm.constObj.LAUNCHER_ICON.right.size ===
                              "medium",
                            "saichat-launcher__largeSize":
                              _vm.constObj.LAUNCHER_ICON.right.size === "large"
                          }
                        },
                        [
                          _c("font-awesome-icon", {
                            staticClass: "animated",
                            class: [
                              _vm.constObj.LAUNCHER_ICON.right.effect.motion,
                              _vm.constObj.LAUNCHER_ICON.right.effect.duration,
                              {
                                infinite:
                                  _vm.constObj.LAUNCHER_ICON.right.effect
                                    .infinite,
                                "saichat-launcher__mediumIcon":
                                  _vm.constObj.LAUNCHER_ICON.right.size ===
                                  "medium",
                                "saichat-launcher__largeIcon":
                                  _vm.constObj.LAUNCHER_ICON.right.size ===
                                  "large"
                              }
                            ],
                            attrs: {
                              icon: _vm.constObj.LAUNCHER_ICON.right.src
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c("transition", { attrs: { name: "show" } }, [
            _vm.isWindowOpen
              ? _c(
                  "div",
                  { ref: "saichatContainer", staticClass: "saichat-container" },
                  [
                    _c("Header", {
                      on: {
                        toggleWindow: _vm.toggleWindow,
                        clearMessages: _vm.clearMessages
                      }
                    }),
                    _vm._v(" "),
                    _c("Content", {
                      attrs: { isIphone: _vm.isIphone },
                      on: {
                        selectAnswer: _vm.selectAnswer,
                        scrollTop: _vm.scrollTop
                      }
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      { ref: "inputContent" },
                      [
                        _vm.isSuggestion
                          ? _c("Suggestion", {
                              attrs: {
                                userInputChoices: _vm.userInputChoices,
                                isIphone: _vm.isIphone
                              },
                              on: {
                                updateSuggestionParams:
                                  _vm.updateSuggestionParams,
                                selectSuggest: _vm.selectSuggest,
                                clearSuggestion: _vm.clearSuggestion
                              }
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _c("UserInput", {
                          ref: "userInput",
                          attrs: { isSP: _vm.isSP, isIphone: _vm.isIphone },
                          on: {
                            onChangeInputMessage: _vm.onChangeInputMessage,
                            sendMessage: _vm.sendMessage,
                            updateIsFocusMessageInput:
                              _vm.updateIsFocusMessageInput
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.isEnqueteOpen
                      ? _c("Enquete", {
                          attrs: {
                            enquetes: _vm.getEnquetes,
                            enqueteMode: _vm.enqueteMode
                          }
                        })
                      : _vm._e()
                  ],
                  1
                )
              : _vm._e()
          ])
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }